import React, {useEffect, useState} from "react";
import general from "../../general";
import {Link} from "react-router-dom";
import Image from "../../lib/Image";
import Dialog from "../../lib/Dialog";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {CSSTransition} from "react-transition-group";
import {NavLink} from "../../lib/Navigation";
import moment from "moment";
import {useHistory} from "react-router-dom";
import useActiveState from "../../lib/useActiveState";
import Subtitle from "../components/Subtitle";

export default props => {
  const history = useHistory();
  const [data, setData] = useState({});
  const [code, setCode] = useState({});
  const [giftShow, setGiftShow] = useState();
  const [gift, setGift] = useState({});
  const [giftCode, setGiftCode] = useState();
  const [giftDescInfo, setGiftDescInfo] = useState({});
  const [giftDescInfoImage, setGiftDescInfoImage] = useState({});
  const [trumpetState, setTrumpetState] = useState(false);//是否有小号状态--领取礼包
  const [gamePubId, setGamePubId] = useState();
  const [agent] = useActiveState('agent');
  useEffect(() => {
    load()
  }, []);

  const load = () => {
    Dialog.loading();
    general.axios.post('/base/special/getData', {id: props.valueInfo})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        // setTitle(data.title);
        setData(data);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  const receive = item => {
    Dialog.loading();
    general.axios.post('/base/game/getReceiveGift', {gift_id: item.gift_id})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        } else {
          setGiftShow(false);
          setGiftCode(data);
          Dialog.info("领取成功");
          load();
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });

    // console.log(item);
    // Dialog.loading();
    // general.axios.post('/base/game/getReceiveGift', {gift_id: item.gift_id})
    //   .finally(() => {
    //     Dialog.close();
    //   })
    //   .then(res => {
    //     let {status, data} = res.data;
    //     if (!status.succeed) {
    //       Dialog.error(status.error_desc);
    //       return;
    //     }
    //     setGiftShow(false);
    //     setGiftCode(data);
    //     Dialog.info("领取成功");
    //     load();
    //   })
    //   .catch(err => {
    //     Dialog.error(err.message);
    //   });
  }

  const bouncesShow = item => {
    Dialog.loading();
    general.axios.post('/base/gift/getGiftDetail', {gift_id: item.packid})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        let descData = data.gift_info;
        setGiftDescInfo(data.gift_info);
        setGiftDescInfoImage(descData.new_image.thumb);
        
        // setGift(item);
        setGiftShow(true);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }
  

  const receiveVouNew = item => {
    // console.log(item);
    general.axios.post('/receiveVoucher', {voucher_id: item.voucherId})
    .finally(() => {
        Dialog.close();
    })
    .then(res => {
        let {status} = res.data;
        if (!status.succeed) {
            Dialog.error(status.error_desc);
        } else {
          Dialog.success('领取成功');
          load();
        // item.is_received = true;
        // update();
        }
    })
    .catch(err => {
        Dialog.error(err.message);
    });
  }

  // const activityData = item => {
  //   if(data.fuli_type == "news") {
  //     history.push('/game?id='+item.game_id+'&newid='+item.newid+'&source=project');
  //   } else {
  //     history.push('/game?id='+item.game_id);
  //   }
  // }

  const activityData = item => {
    if(data.type == 2 && data.fuli_type == "news") {
      if(item.newid) {
        history.push('/game?id='+item.game_id+'&newid='+item.newid+'&source=project');
      } else {
        history.push('/game?id='+item.game_id);
      }
    } else {
      history.push('/game?id='+item.game_id);
    }
  }

  // <div className={'game-project style-' + data.style} /*style={{backgroundColor: data.big_block_color}}*/>
  return <div className={'game-project style-' + data.style} style={{backgroundColor: '#f5f6f8',paddingTop:'calc(env(safe-area-inset-top) + 45px)'}}>
    <div className="project-img"><img src={data.top_image?data.top_image:'/resources/placeholder/project-icon.png'} alt="" /></div>
    {/* <div className="project-info" style={{backgroundColor: data.tips_bg_color, color: data.tips_font_color}}>{data.desc}</div> */}
    <div className="project-info">{data.desc}</div>

    {
      data.is_show_jump_voucher ? 
      <div className="project-vouch-img">
        <Link to="/voucher">
          <img src="/resources/index/index-project-vouch.gif"/>
        </Link>
      </div>
      :
      null
    }

    <div className="project-items">
      {data.projectGameslist?.map((game, index) => <div key={index} className={'project-item' + (index % 4 === 0 ? ' special' : '')}><div onClick={()=>activityData(game)} >
        <div className="item-base">
          <div className="icon">
            <Image src={game.game_image.thumb}/>
            {/* <div className="scrole-stat">
              <img src="/resources/game/stat.png" />
              <span>{game.score}</span>
            </div> */}
            {
              game?.lablePosition == 2 && game.top_lable != '' ?
              <div className="iconlable jx" style={{backgroundImage: 'linear-gradient('+game?.lableColor.t+', '+game?.lableColor.b+')'}}>
                {game.top_lable}
              </div>
              : null
            }
          </div>
          <div className="text">
            <div className="name">
              {/* {game.top_lable != '' ? <div className="span">{game.top_lable }</div> : null }{game.game_name} */}
              <div className={" " + (game.nameRemark ? " name-new" : "")}>
                {game?.lablePosition == 1 && game.top_lable != '' ? <div className="span">{game.top_lable }</div> : null }
                {game.game_name}
              </div>
            </div>
            <div className="info1" style={{marginTop:(Object.keys(game.bottom_lable).length > 0 ? '0' : '8px')}}>
              <img src="/resources/game/stat.png" />
              <span>{game.score}</span>
              {game.game_classify_type}
              <Subtitle>{game.nameRemark}</Subtitle>
            </div>
            {/* <div className="info2">
              {game.game_desc ? game.game_desc.split('+').map((item,index) => <span key={index} className="tag">{item}</span>) : game.introduction}
            </div> */}
            {
              game.bottom_lable ? 
              <>
              {
                
                Object.keys(game.bottom_lable).length > 0 ?
                <div className="info3">
                  <span className="second-text" style={{color:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC',borderColor:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.right_content}</span>
                </div>
                :null
              }
              </>
            :null}
          </div>
          {game.discount < 1 ? <div className="discount" style={{backgroundImage:'url(/resources/game/discount-text-bg.png)'}}><span>{game.discount * 10}</span>折</div> : null}
          {/* <div className="btn">下载</div> */}
        </div>
        
        {(()=>{
          if(data.style != '4' && data.style != '5') { 
            return <div>
              <div className="item-img"><Image src={game.image}/></div>
              {
                game.fuli_desc ? <div className="item-text" dangerouslySetInnerHTML={{
                  __html: game.fuli_desc.substring(0, 100)
                      .replace(/(\r\n|\n\r|\r|\n)+/g, '\n')
                      .replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2')
                    + '...'
                }}/>
                : null
              }
            </div>
          }
        })()}
      </div>
      {(()=>{
          if(data.style == '4') {
            return <div>
                <div className="item-gift-text">
                  <div className="text-title" style={{backgroundImage: 'url(/resources/game/style4-bg.png)'}}>
                    {game.packname}
                  </div>
                  <div className="text-info">
                    <div className="info-content">
                      {game.packcontent}
                    </div>
                    <div className="info-btn">
                      {
                        game.isreceived ? <CopyToClipboard text={game.card} onCopy={() => Dialog.info('已复制')}>
                        <div className="btn-copy">复制</div>
                        </CopyToClipboard> :
                        <div className="btn-receive"  onClick={()=>{bouncesShow(game);setGamePubId(game.game_id);}}>{/*onClick={()=>receive(game)} */}
                        领取
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <div className="item-gift-desc">
                  <div className="desc-title" style={{backgroundImage: 'url(/resources/game/style4-bg02.png)'}} >
                    <div className="instru-icon">
                      <img src='/resources/game/wh.png' />
                    </div>
                    使用说明
                  </div>
                  <div className="desc-info">
                    <div className="desc-content">
                      {game.use_desc}
                    </div>
                  </div>
                </div>
            </div>
          } else if(data.style == '5') {
            return <div>
              <div className="item-five-gift">
                <div className="five-amount">
                  ￥<span>{game.amount}</span>
                </div>
                <div className="five-desc">
                  {game.voucherDesc}
                </div>
                <div className="operate">
                    {game.received ? 
                    <div className="alreadly-operate-btn">已领取</div>
                    :
                    <div className="operate-btn five-relave-but"  onClick={() => receiveVouNew(game)} >领取</div>
                    }
                </div>
              </div>
            </div>
          }
        })()}
      </div>)}
      <CSSTransition in={giftShow} timeout={250} unmountOnExit={true}>
        <div className="gift-detail">
          <div className="gift-mask" onClick={() => setGiftShow(false)}/>
          <div className="gift-container">
            <div className="gift-top">
              <div className="icon">
              <Image src={giftDescInfoImage}/>
              </div>
              <div className="text">
                <div className="name">{giftDescInfo.gift_name}</div>
                <div className="time">有效期：{moment(giftDescInfo.gift_dealine * 1000).format('YYYY-MM-DD')}</div>
              </div>
            </div>
            <div className="gift-main">
              <div className="gift-desc" style={{borderBottom:"1px solid #ededed",padding:"0 0 10px 0"}}>
                <div className="title">礼包说明</div>
                <div className="main">{giftDescInfo.gift_desc}</div>
              </div>
              <div className="gift-desc" style={{borderBottom:"1px solid #ededed",padding:"10px 0"}}>
                <div className="title">使用说明</div>
                <div className="main">{giftDescInfo.gift_introduce}</div>
              </div>
            </div>
            <NavLink className="gift-bottom" onClick={() => receive(giftDescInfo)}>领取</NavLink>
          </div>
        </div>
      </CSSTransition>
      {giftCode ? <div className="dialog active">
        <div className="dialog-mask"/>
        <div className="dialog-content">
          <div className="dialog-modal">
            <div className="modal-body">
              <div className="modal-title">领取成功</div>
              <div className="modal-content">{giftCode}</div>
            </div>
            <div className="modal-btns">
              <div className="modal-btn">
                <CopyToClipboard text={giftCode} onCopy={() => Dialog.info('已复制')}>
                  <div onClick={() => setGiftCode(null)}>复制</div>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>
      </div> : null}

      {
        trumpetState ?
        <div className="game-trumpet-index">
          <div className="trumpet-site">
            <div className="trumpet-main">
                <div className="trumpet-title">
                温馨提示
                </div>
                <div className="info">
                  <p>您游戏中尚未创建小号，暂时无法领取此礼包</p>
                </div>
                <div className="trumpet-btn">
                  <div className="item btn-about" onClick={()=>{setTrumpetState(false)}}>
                    稍后再领
                  </div>
                  <NavLink to={"/game?id="+gamePubId+"&isExists=1"} className="item btn-confirm" onClick={()=>{setTrumpetState(false);setGiftShow(false);}}>
                    <h2>{giftDescInfo.type == 3 ? '进入游戏' : '下载游戏'}</h2>
                  </NavLink>
                </div>
            </div>
          </div>
        </div>
        : null
      }
    </div>
    <div className="view-end">别撩啦！已经到底啦~</div>
  </div>;
};