import {useEffect, useState, useCallback} from "react";
import general from "../../../general";
import {NavLink} from "../../../lib/Navigation";
import moment from "moment";
import Dialog from "../../../lib/Dialog";
import Image from "../../../lib/Image";
import {useUpdate} from "ahooks";
import Explain from "../../voucher/Explain";
import useUrlState from "@ahooksjs/use-url-state";
import useActiveState from "../../../lib/useActiveState";

export default props => {
  const [game] = useState(general.game);
  const [explain, setExplain] = useState();
  const [params, setParams] = useUrlState({}, {navigateMode: 'replace'});
  const [data, setData] = useState();
  const [user, setUser] = useActiveState('user');
  const [remindState, setRemindState] = useState(false);
  const update = useUpdate();
  const receive = voucher => {
    if(voucher.vip_level > 0 && user.vip_level == '') {
      setRemindState(true);
      return;
    }

    general.axios.post('/receiveVoucher', {voucher_id: voucher.id})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          let available_voucher = (parseInt(user.available_voucher) + 1).toString();
          setUser({...user, ...{available_voucher: available_voucher}});
          Dialog.success('领取成功');
          // voucher.is_received = true;
          // update();

          if(!data.hasSome) {
            voucher.is_received = true;
            update();
          }
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  const load = () => {
    general.axios.post('/user/voucher/getVouchers', {game_id: params.id})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          setData(data);
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
    
  }

  useEffect(()=>{
    if(props.active) {
      load();
    }
  }, [props.active]);

  const ref = useCallback(node => {
    if (node) {
      node.addEventListener('touchstart', e => {
        e.stopPropagation();
      }, {passive: false});
      node.addEventListener('touchmove', e => {
        e.stopPropagation();
      }, {passive: false});
      node.addEventListener('touchend', e => {
        e.stopPropagation();
      }, {passive: false});
    }
  }, []);

  return <div className="game-coupon-index" >
    <div className="game-voucher">
      <div className="container">
        {/* {game?.game_info?.game_species_type != '2' && game?.game_info?.game_species_type != '3' ?
        // <NavLink to="/save?type=card" className="tips">
        <NavLink to="/save/newcard" className="tips" style={{ display: "none" }}>
          <div className="icon"><img src="/resources/game/voucher-icon3.png" alt=""/></div>
          <div className="name">四倍返还</div>
          <div className="info">开通立返全额 每天领5元</div>
          <div className="operate">></div>
        </NavLink> 
        : null} */}

        <div className="body" ref={ref}>
          {data?.map(voucher => <div className="item" style={{backgroundImage: voucher.vip_level ? voucher.vip_level > 0 ? ('url(/resources/game/vouch-bag.png)') : ('url(/resources/game/vouch-bag.png)') :('url(/resources/game/vouch-bag.png)')}}>
            <div className={"amounts " + (voucher.vip_level ? voucher.vip_level > 0 ? "amounts" : "" : "")}>
              {/* {
                voucher.vip_level ? 
                  voucher.vip_level > 0 ?
                  <div className="vip-label">
                    <img src="/resources/game/vip-label.png" />
                    <div className="text">
                      {voucher.vip_level_desc}
                    </div>
                  </div>
                  :null
                :null
              } */}
              <div className="receive-count" 
                   style={{backgroundImage:'url(/resources/game/receive-count.png)'}}>
                   {voucher.receiveMaxDesc}
              </div>
              <div className="amount">
                <div className="amount-unit">￥</div>
                <div>{voucher.amount * 1}</div>
              </div>
              <div className="amount-meet">
              {(() => {
                let meet_amount = voucher.meet_amount * 1;
                if(meet_amount === 0) {
                  return '任意金额可用';
                } else {
                  return '满' + meet_amount + '元可用';
                }
              })()}
              </div>
            </div>
            <div className="info">
              <div className="name">{`仅限【${voucher.game_name}】使用`}</div> 
              <div className="row-info">
                <div className="vip" style={{display:voucher.vip_level > 0 ? '' : 'none'}}>VIP专享</div>
                <div className="sing-voucher">{voucher.game_id.length > 0 ? '单游券' : '通用券'}</div>
                <div className="time">{voucher.useEndDateTime}</div>
              </div>
              {/** <div className="game">仅限《{voucher.game_name}》</div> */}
              {/* <div className={"use " + (voucher.vip_level ? voucher.vip_level > 0 ? "use" : "" : "")} onClick={() => setExplain(voucher)}>使用方法></div> */}
              <div className={"use " } onClick={() => setExplain(voucher)}>使用方法></div>
            </div>
            {voucher.is_received != '0'
              ? <div className="operate disable">已领取</div>
              : 
              // <NavLink className={"operate " + (voucher.vip_level ? voucher.vip_level > 0 ? "vip-operate" : "" : "")} onClick={() => receive(voucher)}>立即领取</NavLink>
              <NavLink className={"operate " } onClick={() => receive(voucher)}>立即领取</NavLink>
            }
          </div>)}
        </div>
      </div>
      {explain ? <Explain voucher={explain} setVoucher={setExplain}/> : null}
    </div>

    {/** 领取弹框提示 **/}
    {remindState ?
    <div className="vip-remind-index">
      <div className="remind-site">
        <div className="remind-main">
          <div className="close" onClick={()=>setRemindState(false)}>
            <img src="/resources/user/close.png" />
          </div>
            <div className="remind-title">
            温馨提示
            </div>
            <div className="info">
              <p>仅限会员可领取</p>
            </div>
            <div className="remind-btn">
              <NavLink to="/save/newvip" className="item btn-confirm" onClick={()=>setRemindState(false)}>
                开通会员
              </NavLink>
            </div>
        </div>
      </div>
    </div>
    : null}

  </div>;
};