import { AndroidOutlined, AppleOutlined, CloseOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { DownOutlined, FilterOutlined } from '@ant-design/icons';
import { useClickAway, useInViewport, useLockFn, useScroll, useUpdateEffect } from "ahooks";
import { Link } from "react-router-dom";
import general from "../../general";
import moment from "moment";
import $ from "jquery";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import { CSSTransition } from "react-transition-group";
import useActiveState from "../../lib/useActiveState";
import GfyxItem from "../components/GfyxItem";
import CzjlItem from "../components/CzjlItem";

const sorts = [
  {
    sort: 'publish',
    text: '最新发布',
  },
  {
    sort: 'effective',
    text: '性价比高',
  },
  {
    sort: 'asc',
    text: '价格升序',
  },
  {
    sort: 'desc',
    text: '价格降序',
  },
];
const types = [
  {
    type: '1',
    text: 'BT版',
  },
  {
    type: '2',
    text: '折扣',
  },
  {
    type: '3',
    text: 'H5',
  },
  {
    type: '5',
    text: '0.1折',
  },
  {
    type: '6',
    text: 'BT折扣',
  },
  {
    type: '7',
    text: '0.05折',
  },
];
const devices = [
  {
    device: '0',
    text: '双端',
  },
  {
    device: '2',
    text: '苹果',
  },
  {
    device: '1',
    text: '安卓',
  },
];
const initFilter = {
  type: undefined,
  device: undefined,
  minPrice: undefined,
  maxPrice: undefined,
  tag: undefined,
};

const pricerange = [
  {
    minPrice: '1',
    maxPrice: '6',
    text: '1-6元',
  },
  {
    minPrice: '6',
    maxPrice: '20',
    text: '6-20元',
  },
  {
    minPrice: '20',
    maxPrice: '50',
    text: '20-50元',
  },
  {
    minPrice: '50',
    maxPrice: '100',
    text: '50-100元',
  },
  {
    minPrice: '100',
    maxPrice: '999999',
    text: '100元以上',
  },
];

export default props => {
  const { aplus_queue } = window;
  const [user] = useActiveState('user');
  const [agent] = useActiveState('agent');
  const [sortIng, setSortIng] = useState();
  const [sort, setSort] = useState(0);
  const [filtering, setFiltering] = useState();
  const [filter, setFilter] = useState(initFilter);
  const [tmpFilter, setTmpFilter] = useState({});
  const [tags, setTags] = useState([]);
  const [kw, setKw] = useState('');
  const [priceRouteIndex, setPriceRouteIndex] = useState(-1);
  const [loaded, setLoaded] = useState(false);
  const sortRef = useRef(null);
  useClickAway(() => {
    setSortIng(false);
  }, sortRef);

  const loadTag = useLockFn(async () => {
    // Dialog.loading();
    // location [1:发现， 2：开服， 3：交易]
    let tmp = {
      location:3
    };
    tmp.location = 3;
    general.axios.post('/base/game/getClassifyList', tmp)
      .finally(() => {
        Dialog.close();
      })
      .then(response => {
        let { status, data } = response.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setTags(data.game_classify_list[0].sub_classify_list[0]);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });
  useEffect(() => {
    if (filtering) {
      setTmpFilter(filter);
    }
    if (tags.length === 0 && filtering) {
      loadTag();
    }
  }, [filtering]);

  const [page, setPage] = useState(1);
  const [trades, setTrades] = useState([]);
  const [more, setMore] = useState(true);

  const load = useLockFn(async (parms = {}) => {
    if (props.active) {
      Dialog.loading();
    }

    let data = {
      member_id: undefined,
      username: undefined,
      sort_type: sorts[sort].sort,
      game_species_type: filter.type ? types[filter.type].type : '',
      game_device_type: filter.device ? devices[filter.device].device : '',
      trade_featured: props.good ? 1 : 0,
      trade_source: props.source,
      game_name: kw,
      pagination: {count: 10, page},
    };

    //超值捡漏
    if (data.trade_source == 'czjl') {
      if (priceRouteIndex != '-1') {
        data.trade_price_range = pricerange[priceRouteIndex].minPrice + '-' + pricerange[priceRouteIndex].maxPrice;
      }
    } else {
      if (filter.minPrice && filter.maxPrice) {
        data.trade_price_range = filter.minPrice + '-' + filter.maxPrice;
      }
    }

    if (tags.length > 0) {
      if (filter.tag !== undefined) {
        data.game_classify_id = tags[filter.tag].game_classify_id;
      }
    }
    if (parms.page) {
      data.pagination.page = parms.page;
    }
    if (data.trade_source == 'czjl') {
      setStyleState(false);//竖条显示
      setIsHideSwitch(true);//隐藏切换样式
    }
    const url = (props.type === 'project' ? '/user/trade/getExtraleak' : '/user/trade/getTradeList')
    await general.axios.post(url, data)
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let { status, data: { account_list, paginated } } = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setLoaded(true);
        if (parms.page) {
          setPage(parms.page);
        }
        setTrades(trades => parms.page === 1 || !parms.page ? account_list : [...trades, ...account_list]);
        if (paginated) {
          setMore(paginated.more && account_list.length > 0);
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });
  useEffect(() => {
    load({ page: 1 });
  }, [sort, filter, kw]);

  const scroll = useScroll();
  useUpdateEffect(() => {
    if (props.active) {
      if (scroll.top < -80) {
        load({ page: 1 });
      }
      if (scroll.top + window.innerHeight + 20 >= $(document).height() && more) {
        load({ page: page + 1 });
      }
    }
  }, [scroll, props.active]);

  const headRef = useRef(null);
  const headInViewPort = useInViewport(headRef);
  const [opacity, setOpacity] = useState();
  // const [headNewInViewPort, setHeadNewInViewPort] = useState(true);
  useEffect(() => {
    // console.log(headInViewPort);
    // setTimeout(()=>{setHeadNewInViewPort(headInViewPort)}, 300);
  }, [headInViewPort]);

  useEffect(() => {
    let opacity;
    if (props.active) {
      if (scroll.top > 280) {
        // opacity = Math.abs(scroll.top) / 200;
        // if (opacity > 1) {
        //   opacity = 1;
        // }
        opacity = false;
      } else {
        opacity = true;
      }
    }
    setOpacity(opacity);
  }, [scroll, props.active]);

  const [styleState, setStyleState] = useState(true);
  const [isHideSwitch, setIsHideSwitch] = useState(false);

  const upStyleImg = () => {
    let a = styleState == true ? false : (styleState == false ? true : false);
    setStyleState(a);
  }

  //搜索处理
  const [inputFlag, setInputFlag] = useState(false);
  const [inputText, setInputText] = useState('');
  let isOnComposition = false;

  const handleComposition = (e) => {
    //中文输入状态
    if (e.type === 'compositionend') {
      isOnComposition = false;
      setInputText(e.target.value);
      setKw(e.target.value);
      return;
    } else if (e.type === 'compositionstart') {
      isOnComposition = true;
      return;
    } else if (e.type === 'compositionupdate') {
      isOnComposition = true;
      return;
    }
    isOnComposition = true;
  }

  const handleChange = (e) => {
    setInputText(e.target.value);
    if (!isOnComposition) {
      setKw(e.target.value);
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['SearchOnTradePage', 'CLK', { age: user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), searchinfo: e.target.value, agent: agent }]
      });
    }
  }

  const priceMinDeal = (e) => {
    let value = e.target.value.replace(/[^\d]/, '')
    setTmpFilter({ ...tmpFilter, minPrice: value });
  }

  const priceMaxDeal = (e) => {
    let value = e.target.value.replace(/[^\d]/, '')
    setTmpFilter({ ...tmpFilter, maxPrice: value })
  }

  //超值捡漏
  useEffect(() => {
    if (props.source == 'czjl') {
      load({ page: 1 });
    }
  }, [priceRouteIndex]);

  return <div className="trade-list">
    <div className={'list-head-place' + (!opacity ? ' active' : '')} ref={headRef} />
    <div className={'list-head' + (!opacity ? ' fixed' : '')}>
      <div className="sort" onClick={() => setSortIng(true)} ref={sortRef}>
        <span className="title">{sorts[sort].text}</span>
        {/* <DownOutlined className="icon" /> */}
        <img src="/resources/trade/down-jt.png" className="icon" style={{ width: '10px' }} />
      </div>
      <CSSTransition in={sortIng} timeout={250} unmountOnExit={true}>
        <div className="sorts">
          {sorts.map((item, index) => <div key={index} className={'item' + (sort === index ? ' active' : '')} onClick={() => {
            setSort(index);
            setSortIng(false);
          }}>{item.text}</div>)}
        </div>
      </CSSTransition>
      <img src="/resources/trade/trade-xz.png" className="filter" onClick={() => setFiltering(true)} />
      {/* <FilterOutlined className="filter" onClick={() => setFiltering(true)}/> */}
      <div className="input">
        {/* <input type="text" placeholder="输入关键字搜索" value={kw} onChange={e => setKw(e.target.value)}/> */}
        <input type="text" value={inputText || ''} placeholder="搜索游戏"
          onCompositionStart={handleComposition}
          onCompositionUpdate={handleComposition}
          onCompositionEnd={handleComposition}
          onChange={handleChange}
        />
        {kw || inputText ? <CloseOutlined className="close" onClick={() => { setKw(''); setInputText('') }} /> : null}
        {/* <SearchOutlined className="icon"/> */}
        <img src="/resources/user/ss.png" className="icon" />
      </div>
      {/* {
        isHideSwitch ?
        null
        : 
        <div className="switch-img" onClick={upStyleImg}>
          <img src={styleState? "/resources/trade/block-true.png" : "/resources/trade/block-false.png"} />
        </div>
      } */}
    </div>

    {/* {props.source == 'czjl' ?
      <div className={"list-select " + (!opacity ? ' fixed' : '')}>
        <div className="items">
          {
            pricerange.map((t, i) => <div key={i} className={"item " + (priceRouteIndex == i ? 'active' : '')} onClick={() => { i == priceRouteIndex ? setPriceRouteIndex(-1) : setPriceRouteIndex(i) }}>
              {t.text}
            </div>)
          }
        </div>
      </div>
      : null} */}

    <div className="list-body clearfix">
    {trades?.map((item,index)=>{
        return (
          <>
          {props.good ? <CzjlItem key={item.trade_id}>{item}</CzjlItem> : <GfyxItem key={item.trade_id}>{item}</GfyxItem>}
          </>
        )
      })}
    </div>
    {trades.length > 0 && !more ? <div className="view-end">别撩啦！已经到底啦~</div> : null}
    {trades.length === 0 && loaded ? <div className="recovery-empty"><img src="/resources/game/detail-trade-place.png" alt="" />暂无该游戏交易</div> : null}
    <CSSTransition in={filtering} timeout={250} unmountOnExit={true}>
      <div className="list-filter">
        <div className="mask" onClick={() => setFiltering(false)} />
        <div className="container">
          <div className="title" style={{ display: "none" }}>游戏专区</div>
          <div className="type" style={{ display: "none" }}>
            {types.map((item, index) =>
              <div key={index} className={'item' + (index === tmpFilter.type ? ' active' : '')}
                onClick={() => setTmpFilter({ ...tmpFilter, type: index })}>{item.text}
              </div>)
            }
          </div>
          <div className="title">设备</div>
          <div className="type">
            {devices.map((item, index) => <div key={index} className={'item' + (index === tmpFilter.device ? ' active' : '')} onClick={() => setTmpFilter({ ...tmpFilter, device: index })}>{item.text}</div>)}
          </div>
          {props.source != 'czjl' ?
            <div>
              <div className="title">价格区间</div>
              <div className="prices">
                <input type="number" placeholder="最低价格" value={tmpFilter.minPrice} onChange={priceMinDeal} />
                <span>~</span>
                <input type="number" placeholder="最高价格" value={tmpFilter.maxPrice} onChange={priceMaxDeal} />
              </div>
            </div>
            : null}
          <div className="title">游戏分类</div>
          <div className="tag">
            <div className={'item' + (tmpFilter.tag === undefined ? ' active' : '')} onClick={() => setTmpFilter({ ...tmpFilter, tag: undefined })}><span>全部</span></div>
            {tags.map((item, index) => <div className={'item' + (index === tmpFilter.tag ? ' active' : '')} onClick={() => setTmpFilter({ ...tmpFilter, tag: index })}><span>{item.game_classify_name}</span></div>)}
          </div>
          <div className="operate">
            <div className="btn" onClick={() => setTmpFilter(initFilter)}>重置</div>
            <div className="btn" onClick={() => {
              setFiltering(false);
              setFilter({ ...tmpFilter });
            }}>确定
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  </div>;
};