import {useCallback, useEffect, useRef, useState} from "react";
import {QuestionCircleOutlined, SyncOutlined, PlayCircleOutlined, UpOutlined, DownOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {useLocalStorageState, useReactive, useSize} from "ahooks";
import general from "../../../general";
import {NavLink} from "../../../lib/Navigation";
import Dialog from "../../../lib/Dialog";
import Image from "../../../lib/Image";
import { CSSTransition } from "react-transition-group";
import GameWelfare from "../GameWelfare";
import GameDesc from "../GameDesc";

export default props => {
  const [game, setGame] = useState({});
  useEffect(() => {
    setGame(props.game);
  }, [props.game]);

  const [welfareMore, setWelfareMore] = useState(true);
  const [welfareMored, setWelfareMored] = useState(false);
  const welfareBodyRef = useRef();
  const welfareEntityRef = useRef();
  const welfareBodySize = useSize(welfareBodyRef);
  const welfareEntitySize = useSize(welfareEntityRef);

  useEffect(() => {
    if(welfareEntitySize.height && welfareBodySize.height) {
      !welfareMored && setWelfareMore(welfareEntitySize.height > welfareBodySize.height);
    }
  }, [welfareBodySize, welfareEntitySize]);

  const [rebateMore, setRebateMore] = useState(true);
  const [rebateMored, setRebateMored] = useState(false);
  const rebateBodyRef = useRef();
  const rebateEntityRef = useRef();
  const rebateBodySize = useSize(rebateBodyRef);
  const rebateEntitySize = useSize(rebateEntityRef);
  useEffect(() => {
    if(rebateEntitySize.height && rebateBodySize.height) {
      !rebateMored && setRebateMore(rebateEntitySize.height > rebateBodySize.height);
    }
  }, [rebateBodySize, rebateEntitySize]);

  const [introMore, setIntroMore] = useState(true);
  const [introMored, setIntroMored] = useState(false);
  const introBodyRef = useRef();
  const introEntityRef = useRef();
  const introBodySize = useSize(introBodyRef);
  const introEntitySize = useSize(introEntityRef);
  useEffect(() => {
    if(introEntitySize.height && introBodySize.height) {
      !introMored && setIntroMore(introEntitySize.height > introBodySize.height);
    }
  }, [introBodySize, introEntitySize]);

  const [companyMored, setCompanyMored] = useState(false);

  const [player, setPlayer] = useState();
  const [muted, setMuted] = useLocalStorageState('game-video-muted', true);
  const video = useReactive({});
  const [videoPlayState, setVideoPlayState] = useState();
  const [game_info_show,setGame_info_show] = useState();

  const onPlaying = () => {
    video.play = true;
    video.eventPause = false;
    video.activePause = false;
  }
  const onPause = () => {
    video.play = false;
    video.eventPause = true;
  }

  const imgRef = useCallback(node => {
    if (node) {
      node.addEventListener('touchstart', e => {
        e.stopPropagation();
      }, {passive: false});
      node.addEventListener('touchmove', e => {
        e.stopPropagation();
      }, {passive: false});
      node.addEventListener('touchend', e => {
        e.stopPropagation();
      }, {passive: false});
    }
  }, []);

  const [data, setData] = useState([]);
  const [init, setInit] = useState(false);

  const recommend = () => {
    if(!game.game_id) {
      return;
    }
    // Dialog.loading();
    general.axios.post('/base/newindex/GameLike', {game_id: game.game_id})
      .finally(() => {
        Dialog.close();
      })
      .then(response => {
        let {status, data} = response.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }

        setInit(true);
        setData(data.list);
        // props.setGame(game => ({...game, recommend_info: data.list}));
      })
      .catch(err => {
          Dialog.error(err.message);
        });
  }

  useEffect(()=>{
    // recommend();
    if(game && !init) {
      recommend();
    }
  }, [game])

  // console.log(game.game_feature_list ? game.game_feature_list[0].content : null);
  const [teseState, setTeseState] = useState(false);
  useEffect(() => {
    if(game.game_feature_list) {
      // console.log(game.game_feature_list[0].content);
      if(game.game_feature_list[0].content.trim()) {
        setTeseState(true);
      }
    }
  }, [game.game_feature_list]);

  // console.log(teseState);

  return <div className="game-index">
    
    {game.game_feature_list && game.game_feature_list[0].content.trim().length > 0 && <div className="game-imgs-intro" onClick={()=>setWelfareMored(true)}>

      <div className="head">
        <div className="subject">
          <div className="gamedesc">游戏福利</div>
          <img style={{margin:'0'}} width='15px' className="right" src='resources/game/more.png'></img>
        </div>
      </div>
      <div className={"game-intro"} ref={introBodyRef}>
        <div className="introduce" dangerouslySetInnerHTML={{
            __html: game.game_feature_list[0].content.replace(/([*\r\n]?)(\r\n|\n\r)/g, '$1<br>$2') 
          }}>
        </div>
      </div>
    </div>}
    
    <div className="game-imgs-intro" onClick={() => setIntroMored(true)}>
      <div className="head">
        <div className="subject">
          <div className="title">游戏介绍</div>
          <img style={{margin:'0'}} width='15px' className="right" src='resources/game/more.png'></img>
        </div>
      </div>

      <div className={"game-intro "} ref={introBodyRef}>
        <div className="entity">
          {game.game_introduce}
        </div>
      </div>
    </div>

    {welfareMored && <GameWelfare {...props} setWelfareMored={()=>setWelfareMored(false)}></GameWelfare>}
    {introMored ? <GameDesc {...props} setIntroMored={()=>setIntroMored(false)}></GameDesc> :null}

    {/* <CSSTransition in={game_info_show} timeout={250} unmountOnExit={true} onExiting={()=>{
      setWelfareMored(false);
      setRebateMored(false);
    }}>
      <div className="game-alert" >
        <div className="mask" onClick={()=>setGame_info_show(false)}></div>
        <div className="content">

          <div className="game-explain">
            {game.game_feature_list && game.game_feature_list[0].content && teseState ?  
            <div className="item" onClick={() => setWelfareMored(true)}>
              <div className="head">
                <img style={{marginLeft:'10px'}} width='25px' src="resources/game/alert-point.png" alt="" />
                <div className="subject">
                  <div className="title">上线福利</div>
                    {parseInt(game.is_both) ? <>
                    <div className="info" onClick={() => Dialog.alert('温馨提示', '双端互通就是游戏的数据在安卓端和苹果端都能进行角色互通共享的游戏！')}>【双端互通】</div>
                    <div className="tips" onClick={() => Dialog.alert('温馨提示', '双端互通就是游戏的数据在安卓端和苹果端都能进行角色互通共享的游戏！')}><QuestionCircleOutlined style={{fontSize:'0.9em'}}/></div>
                  </> : null}
              </div>
            </div>
            <div className={'body ' + (welfareMored ? 'more' : '')} ref={welfareBodyRef}>
              <div className="entity" ref={welfareEntityRef} dangerouslySetInnerHTML={{__html: game.game_feature_list ? game.game_feature_list[0].content.replace(/([*\r\n]?)(\r\n|\n\r)/g, '$1<br>$2') : null}}/>
              {welfareMore
                  ? <div className={'more ' + (welfareMored ? 'ed' : '')}/>
                  : null}
              </div>
            </div> : null}

            {game.game_feature_list && game.game_feature_list[1].content.trim() ? 
            <div className="item" onClick={() => setRebateMored(true)}>
              <div className="head">
                <img style={{marginLeft:'10px'}} width='25px' src="resources/game/alert-point.png" alt="" />
                <div className="subject">
                  <div className="title">返利</div>
                  {game.allow_rebate ? <>
                    <div className="info" onClick={() => Dialog.alert('温馨提示', '充值达到返利标准后，需要通过app手动申请返利，提交审核后24小时内到账！')}>【手动返利】</div>
                    <div className="tips" onClick={() => Dialog.alert('温馨提示', '充值达到返利标准后，需要通过app手动申请返利，提交审核后24小时内到账！')}><QuestionCircleOutlined/></div>
                  </> : <>
                    <div className="info" onClick={() => Dialog.alert('温馨提示', '充值达到返利标准后，无需手动申请，游戏一般为次日0点会自动到账！')}>【自动返利】</div>
                    <div className="tips" onClick={() => Dialog.alert('温馨提示', '充值达到返利标准后，无需手动申请，游戏一般为次日0点会自动到账！')}><QuestionCircleOutlined/></div>
                  </>}
                </div>
                {game.allow_rebate ? <NavLink to={'/rebate?id=' + game.game_id} className="operate">申请返利 {'>'}</NavLink> : null}
              </div>
              <div className={'body ' + (rebateMored ? 'more' : '')} ref={rebateBodyRef}>
                <div className="entity" ref={rebateEntityRef} dangerouslySetInnerHTML={{__html: game.game_feature_list ? game.game_feature_list[1].content.replace(/([^>\r\n\r\n]?)(\r\n\r\n|\n\r|\r\n|\r|\n)/g, '$1<br>$2') : null}}/>
                {rebateMore
                    ? <div className={'more ' + (rebateMored ? 'ed' : '')}/>
                    : null}
              </div>
            </div> : null}

            <div className="game-imgs-intro" onClick={() => setIntroMored(true)}>
            <img style={{float:'left',marginLeft:'-5px'}} width='25px' src="resources/game/alert-point.png" alt="" />
            <div className="head" >
              <div className="subject" style={{margin:'0 0 0 -18px'}}>
                <div className="title">游戏介绍</div>
                {
                  game.game_feature_list && game.game_feature_list[0].content.trim() && teseState ? '' :
                  <>
                  {parseInt(game.is_both) ?
                  <>
                  <div className="info">【双端互通】</div>
                  <div className="tips" onClick={() => Dialog.alert('温馨提示', '双端互通就是游戏的数据在安卓端和苹果端都能进行角色互通共享的游戏！')}><QuestionCircleOutlined/></div>
                  </>
                  : ''  }
                  </>
                }
              
            </div>
            </div>

            <div className={"game-intro " + (introMored ? 'more' : '')} ref={introBodyRef}>
            <div className="entity">
              {game.game_introduce}
            </div>
            {introMore ? 
            <div className={'more ' + (introMored ? 'ed' : '')}/>
              : null}
            </div>
            </div>

          </div>
        </div>
      </div>
    </CSSTransition> */}
    
    {/* <Link to={'/game/vip?id=' + game.game_id} className="game-vip">
      <img src="/resources/game/vip.png"/>
      <p>《{game.game_name || '?'}》VIP表</p>
      <span><img src="/resources/welfare/left-back.png" className="left-back"/></span>
    </Link> */}
    
    {/* <div className={"game-companyinfo " + (companyMored ? " ed" : "")} onClick={() => setCompanyMored(!companyMored)}>
      <div className="companyinfo-item">
        <div className="name">
          供应商信息：
        </div>
        <div className="text">
          {game?.companyInfo?.company}
        </div>
        {game?.appVersion || (game?.companyInfo?.privacyUrl && game?.companyInfo?.roleUrl) ?
        <div className="operate">
          {companyMored ? <UpOutlined></UpOutlined> : <DownOutlined></DownOutlined>}
        </div>
        : null}
      </div>
      {game?.appVersion ?
      <div className={"companyinfo-item " + (companyMored ? " " : " hidden")}>
        <div className="name">
          软件版本：
        </div>
        <div className="text">
          {game?.appVersion}
        </div>
      </div>
      : null}
      {game?.companyInfo?.filingCode ?
      <div className={"companyinfo-item " + (companyMored ? " " : " hidden")}>
        <div className="name">
          软件备案：
        </div>
        <div className="text">
          {game?.companyInfo?.filingCode}
        </div>
      </div>
      : null}
      {game?.companyInfo?.privacyUrl && game?.companyInfo?.roleUrl ?
      <div className={"companyinfo-item "  + (companyMored ? " " : " hidden")}>
        <div className="name">
          隐私权限：
        </div>
        <div className="text privacy">
          <div className="child-index">
            <div className="">权限信息</div>
            {
              game?.companyInfo?.roleUrl.indexOf('https') == -1 ?
              <div className="links" onClick={()=>{window.open(game?.companyInfo?.roleUrl, "_blank")}}>
                查看
              </div>
              :
              <NavLink to={game?.companyInfo?.roleUrl} iframe={{title:"权限信息"}} user={false} className="links">查看</NavLink>
            }
          </div>
          <div className="child-index">
            <div className="">隐私政策</div>
            {
              game?.companyInfo?.privacyUrl.indexOf('https') == -1 ? 
              <div className="links" onClick={()=>{window.open(game?.companyInfo?.privacyUrl, "_blank")}}>
                查看
              </div>
              :
              <NavLink to={game?.companyInfo?.privacyUrl} iframe={{title:"隐私政策"}} user={false} className="links">查看</NavLink>
            }
          </div>
        </div>
      </div>
      : null}
    </div> */}

    <div className="game-games">
      <div className="head">
        <div className="text">类似游戏</div>
        <div className="operate" onClick={() => recommend()}><SyncOutlined className="icon"/>换一批</div>
      </div>
      <div className="body clearfix">
        {data?.map((game, index) => <Link key={index} to={'/game?id=' + game.game_id} className={"item " + (index == 4 ? ' clear' : '')}>
          <div className="icon"><Image src={game.game_image.thumb} alt=""/></div>
          <div className="name">{game.game_name}</div>
          <div className="info">{game.game_classify_type} </div>
          {/* {
            game.nameRemark ?
            <div className="nameRemark">
              <span>
              {game.nameRemark}
              </span>
              
            </div>
            : null
          } */}
        </Link>)}
      </div>
    </div>
    <NavLink to={'/feedback?type=game&id=' + game.game_id} className="game-help">
      游戏有问题？<span>点击反馈</span>
    </NavLink>
  </div>;
};