import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import {Link} from "react-router-dom";
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Autoplay} from 'swiper';
import $ from 'jquery';
import { useMount, useScroll, useThrottleFn, useLockFn } from 'ahooks';
import moment from "moment";
import Dialog from '../../../lib/Dialog';
import general from '../../../general';
import { NavLink } from '../../../lib/Navigation';
import "./css/NewGame.css"
import "swiper/swiper.min.css";
import Image from '../../../lib/Image';
import useActiveState from '../../../lib/useActiveState';
import Subtitle from '../../components/Subtitle';
import { RightOutlined } from '@ant-design/icons';
import {useHistory} from "react-router-dom";
import PubSub from 'pubsub-js';

const swipers = [];
SwiperCore.use([Autoplay]);

export default (props) => {

  const [loading,setLoading] = useState()
  const [banners,setBanners] = useState([])
  const [banner,setBanner] = useState([])
  const [topItems,setTopItems] = useState([])
  const [list,setList] = useState([])
  const [swiper, setSwiper] = useState(null);
  const [init, setInit] = useState();
  const [user, setUser] = useActiveState('user');
  const [agent] = useActiveState('agent');
  const [page, setPage] = useState(1);
  const [gameGroups, setGameGroups] = useState([]);
  const [more, setMore] = useState(true);

  const menuRef = useRef(null);
  const menuScroll = useScroll(menuRef);
  const scrollbarRef = useRef(null);

  const [randomColorInfo] = useActiveState('randomColorInfo');
  const [infoTextColor, setInfoTextColor] = useState();
  const [config] = useActiveState('config');
  const [isCheckAuth, setIsCheckAuth] = useState(false); //实名认证弹框状态
  const [authData, setAuthData] = useState({}); //实名认证参数值
  const history = useHistory();

  const {run: setTravelOpacity} = useThrottleFn(value => props.setTravelOpacity(value), {wait: 10});
  const pathname = location.pathname + 'newgame'
  const scrollTo = () => {
    const scrollY = sessionStorage.getItem(pathname);
    if (scrollY) {
      const scrollResult = Number(scrollY) < 150 ? 0 : Number(scrollY);
      window.scrollTo(0, scrollResult);
    }
  };

  const scroll = useScroll();

  useLayoutEffect(() => {
    if (props.active) {
      scrollTo();
    }else{
      const top = scroll.top
      sessionStorage.setItem(pathname, String(top));
    }
  }, [props.active]);
  useEffect(() => {
    let opacity;
    if (props.active) {
      if (scroll.top) {
        opacity = Math.abs(scroll.top) / 200;
        if (opacity > 1) {
          opacity = 1;
        }
      } else {
        opacity = 0;
      }
    }

    setTravelOpacity(opacity);

    if (props.active) {
      if (scroll.top < -80) {
        loadData();
        setPage(1)
      }
      if (scroll.top + window.innerHeight + 20 > $(document).height() && more) {
        load({page: page + 1});
      }
    }
  }, [scroll, props.active]);

  const loadData = ()=>{
    if (loading){
      return
    }
    setLoading(true)
    Dialog.loading()
    general.axios.post('/base/travel/getTravelreservalGameList')
      .finally(()=>{
        setLoading(false)
        Dialog.close()
        load({page:1})
      })
      .then((response)=>{
        let {status,data} = response.data
        if (!status.succeed){
          Dialog.error(status.error_desc)
          return
        }
        
        setInfoTextColor(randomColorInfo[parseInt(Math.random() * 4)]);
        console.log(data);
        // setInit(true);
        setBanners(data.carousel)
        setTopItems(data.topItems);
        // setTopItems([...data.topItems,...data.topItems])

        let datas = data.list.map(item => ({...item, sort: parseInt(item.sort)}));
        datas.sort((a, b) => {
          if (a.sort < b.sort) {
            return -1
          }
          if (a.sort > b.sort) {
            return 1
          }
          return 0;
        });
        setList(datas)
      })
  }

  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      pagination: {page: page},
    };
    if (parms.page) {
      tmp.pagination = {page: parms.page};
    }
    general.axios.post('/newTravelWeekGames', tmp)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then(response => {
        setInit(true);
        let result = response.data.data.list;
        if (parms.page) {
          setPage(parms.page);
        }
        let tmp = parms.page === 1 || !parms.page ? [] : gameGroups;
        // result.list.forEach(item => {
        //   let index = tmp.findIndex(value => value.text == item.text);
        //   if (index == -1) {
        //     index = tmp.length;
        //     tmp.push({text: item.group, games: []});
        //   }
        //   item.list.forEach(d => {
        //     d.infoColor = randomColorInfo[parseInt(Math.random() * 4)]
        //   })
        //   tmp[index].games = [...tmp[index].games, ...item.list];
        // });
        tmp.push({text: result.group, games: [...result.list]});
        // console.log('tmp',tmp);
        setGameGroups(tmp);
        setMore(false);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  useEffect(() => {
    if (props.active && list.length === 0){
      loadData()
    }
  }, [props.active]);

  useEffect(() => {
    swiper && swiper.update();
  }, [props.active, swiper, banners]);

  useEffect(() => {
    props.active && swipers.forEach(swiper => swiper.update());
  }, [props.active, banners]);

  useEffect(() => {
    if (menuRef.current && props.active && !init) {
      setTimeout(() => {
        $(menuRef.current).animate({scrollLeft: menuRef.current.offsetWidth}, 1000, null, () => {
          $(menuRef.current).animate({scrollLeft: 0}, 1000);
        });
      }, 250);
    }
  }, [props.active, init]);

  useEffect(() => {
    if (menuRef.current && scrollbarRef.current) {

      $(scrollbarRef.current).css({left: (menuScroll.left + $(menuRef.current).width() / 2) / menuRef.current.scrollWidth * 100 + '%'}); //原来的
      $(scrollbarRef.current).css({width: '50%'});//原来的
    }
  }, [menuScroll, init]);

  const clickTheTopicToSeeMore = (title) => {
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['ClickTheTopicToSeeMore', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), agent: agent, project_name: title}]
    });
  }

  const clickTheGameInTheTopic = (gamename, title) => {
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['ClickTheGameInTheTopic', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), gamename: gamename, agent: agent, project_name: title}]
    });
  }

  const certifiy = useLockFn(()=>{
    if(!authData.real_name) {
      Dialog.info('请输入姓名');
      return;
    }
    if(!authData.identity_card){
      Dialog.info('请输入身份证号');
      return;
    }
    Dialog.loading();
    general.axios.post('/user/user/editMemberInfo', {real_name:authData.real_name, identity_card:authData.identity_card})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        
        if (!status.succeed) {
          Dialog.info(status.error_desc);
        } else {
          Dialog.info("认证成功");
          setIsCheckAuth(false);
          setUser({...user, ...{real_name: authData.real_name, identity_card: authData.identity_card, isRealNameAuth: true, isAdult: data.isAdult}});
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
    })

  return (
    <div className='index-newgame'>
      <div className="content">

        <div className="imgs">
          <Swiper
            initialSlide={1}
            watchSlidesProgress={true}
            slidesPerView={'auto'}
            centeredSlides={true}
            loop={true}
            loopedSlides={1}
            onSwiper={setSwiper}
            onSlideChange={swiper => {
              setBanner(swiper.realIndex);
            }}
            // autoplay={{
            //   delay: 3000,
            //   disableOnInteraction: false,
            // }}
            observer={true}
            observeParents={true}
            pagination={{clickable: true}}
            >
            {banners.map((banner,index) => 
              <SwiperSlide key={index}>
                {banner.type === 'activity' || banner.type === 'holidayEvent' ?
                  <NavLink to={(() => {
                      let url = URI(banner.value)
                          .addQuery('username', localStorage.getItem('username'))
                          .addQuery('token', localStorage.getItem('token'))
                          .addQuery('location_url', window.location.origin)
                          .addQuery('origin', 'react')
                          .toString();
                      return url;
                    })()}
                    iframe={{title: '活动'}}
                    className="banner"
                    style={{backgroundImage: 'url(' + banner.img + '), url(/resources/placeholder/index-banner.png)'}}
                  ></NavLink> : 
                  <Link
                    to={(() => {
                      if (banner.type === 'game_info') {
                        return '/game?id=' + banner.gameinfo.game_id;
                      }
                      if (banner.type === 'special') {
                        return '/game/project?id=' + banner.id;
                      }
                    })()}
                    className="banner"
                    style={{backgroundImage: 'url(' + (banner.img ? banner.img : banner.gameinfo.banner_url) + '), url(/resources/placeholder/index-banner.png)'}}
                  >
                    {(() => {
                      if (banner.type !== 'game_info') {
                        return;
                      }
                      let game = banner.gameinfo;
                      return <div className="game">
                        <div className="icon"><Image src={game.game_image.thumb} /></div>
                        <div className="text">
                          <div className="name">
                            <div className={" " + (game.nameRemark ? " name-new" : "")}>
                              {game.game_name}
                            </div>
                          </div>
                          <div className="info1" >
                            <img src="/resources/game/stat.png" />
                            <span>{game.score}</span>&nbsp;
                            {game.game_classify_type}
                            {game.nameRemark && <span style={{color:'#dedede',fontSize:'12px'}}> · {game.nameRemark}</span>}
                          </div>
                          {/* <div className="info2">
                            {game.game_desc.length > 0 ? game.game_desc.split('+').map(item => <div className="tag">{item}</div>) : <div style={{color:infoTextColor}}>{game.introduction}</div>}
                          </div> */}
                        </div>
                        {game.discount < 1 ? 
                          <div className="discount" style={{backgroundImage:'url(/resources/index/newgame-discount.png)'}}>
                            <span>{game.discount * 10}</span>
                            <span>折</span>
                          </div> : null
                        }
                      </div>
                    })()}
                  </Link>
                }
              </SwiperSlide>
            )}
          </Swiper>
        </div>

        <div className="items">
          <div className="top-items" ref={menuRef}>
              {topItems?.map((menu,index) => <div key={index} className={'menu ' + (topItems?.length > 5 ? ' menus' : '')}>
                {(()=>{
                  if(menu.jumpType == 'webview') {
                    return <NavLink  to={(()=>{
                      let url = URI(menu.url)
                      .addQuery('username', localStorage.getItem('username'))
                      .addQuery('token', localStorage.getItem('token'))
                      .addQuery('location_url', window.location.origin)
                      .addQuery('origin', 'react')
                      .addQuery('uid', localStorage.getItem('user_id'))
                      .toString();
                      return url;
                    })()} iframe={{title:menu.browserTitle}} user={menu.isCheckLogin == 1?true:false} onClick={()=>clickIconOfHomePage(menu)}>
                      <div key={menu.title} className='item'>
                        <img src={menu.icon} alt="" />
                        <div>{menu.title}</div>
                      </div>
                    </NavLink>
                  } else if(menu.jumpType == 'syGift'){
                    return <NavLink  to={(()=>{
                      let url = URI(menu.url)
                      .addQuery('username', localStorage.getItem('username'))
                      .addQuery('token', localStorage.getItem('token'))
                      .addQuery('location_url', window.location.origin)
                      .addQuery('origin', 'react')
                      .addQuery('uid', localStorage.getItem('user_id'))
                      .toString();
                      return url;
                    })()} iframe={{title:menu.title}} user={menu.isCheckLogin == 1?true:false} onClick={()=>clickIconOfHomePage(menu)}>
                      <div key={menu.title} className='item'>
                        <img src={menu.icon} alt="" />
                        <div>{menu.title}</div>
                      </div>
                    </NavLink>
                  } else if(menu.jumpType == 'active_novice'){
                    return <NavLink  to={(()=>{
                      let url;
                      if(menu.version == 1) {
                        url = URI(menu.url)
                        .addQuery('username', localStorage.getItem('username'))
                        .addQuery('token', localStorage.getItem('token'))
                        .addQuery('location_url', window.location.origin)
                        .addQuery('origin', 'react')
                        .addQuery('uid', localStorage.getItem('user_id'))
                        .toString();
                      } else {
                        url = '/party/couple';
                      }
                      return url;
                    })()} iframe={(()=>{
                      if(menu.version == '1') {
                        return {title: menu.browserTitle};
                      } else if(menu.version == '2') {
                        return false;
                      }
                    // })()} user={true}>
                    })()} user={menu.isCheckLogin == 1 ? true:false} onClick={()=>clickIconOfHomePage(menu)}>
                      <div key={menu.title} className='item'>
                        <img src={menu.icon} alt="" />
                        <div>{menu.title}</div>
                      </div>
                    </NavLink>
                  } else if(menu.jumpType == 'active_flyback'){
                    return <NavLink  to={(()=>{
                      // console.log(menu.version);
                      let url;
                      if(menu.version == '1') {
                        url = URI(menu.url)
                        .addQuery('username', localStorage.getItem('username'))
                        .addQuery('token', localStorage.getItem('token'))
                        .addQuery('location_url', window.location.origin)
                        .addQuery('origin', 'react')
                        .addQuery('uid', localStorage.getItem('user_id'))
                        .toString();
                        return url;
                      } else if(menu.version == '2'){
                        url = '/party/regression';
                        return url;
                      }
                    })()} iframe={(()=>{
                      if(menu.version == '1') {
                        return {title: menu.browserTitle};
                      } else if(menu.version == '2') {
                        return false;
                      }
                    // })()} user={true}>
                    })()} user={menu.isCheckLogin == 1?true:false} onClick={()=>clickIconOfHomePage(menu)}>
                      <div key={menu.title} className='item'>
                        <img src={menu.icon} alt="" />
                        <div>{menu.title}</div>
                      </div>
                    </NavLink>
                  } else {
                    return <NavLink  to={(()=>{
                      if(menu.jumpType == 'mcard') {
                        // return '/save?type=card';
                        return '/save/newcard';
                      } else if(menu.jumpType == 'rechargeVip'){
                        return '/save/newvip';
                      } else if(menu.jumpType == 'freeReceiveVoucher') {
                        return '/voucher';
                      } else if(menu.jumpType == 'special') {
                        if(menu.style == '6') {
                          return "/party/beta?id=" + menu.id;
                        } else {
                          return '/game/project?id=' + menu.id;
                        }
                      } else if(menu.jumpType == 'trade') {
                        return '/trade';
                      } else if(menu.jumpType == 'openServer') {
                        return '/server';
                      } else if(menu.jumpType == 'goldMall') {
                        return '/shop';
                      } else if(menu.jumpType == 'share') {
                        return config?.inviteFriendsType == 2 ? "/newinvite" : "/invite";
                        // return '/invite';
                      } else if(menu.jumpType == 'noviceTask') {
                        return '/task/novice';
                      } else if(menu.jumpType == 'video') {
                        return '/index/video';
                      } else if(menu.jumpType == 'tradeGoods') {
                        return '/trade/good?source=czjl';
                      } else if(menu.jumpType == 'webview') {
                        // console.log(menu.url);
                        let url = URI(menu.url)
                          .addQuery('username', localStorage.getItem('username'))
                          .addQuery('token', localStorage.getItem('token'))
                          .addQuery('location_url', window.location.origin)
                          .addQuery('origin', 'react')
                          .toString();
                        return url;
                      } else if(menu.jumpType == 'authoriry_record') {
                        return '/user/authority';
                      } else if (menu.jumpType === 'recentGames'){
                        return '/newrank'
                      }else if (menu.jumpType == 'daysServer'){
                        return '/server'
                      }else if (menu.jumpType == 'foretellGames'){
                        return '/reserve'
                      }
                    })()} user={menu.isCheckLogin == 1?true:false} >
                    <div key={menu.title} className='item'>
                      <img src={menu.icon} alt="" />
                      <div>{menu.title}</div>
                    </div>
                  </NavLink>
                  } 
                })()}
              </div>)}
          </div>

          {
            topItems?.length > 5 ?
          
            <div className="scrollbar">
              <div className="thumb" ref={scrollbarRef}/>
            </div>
          : null}
        </div>

        <div className="list">
          {list.map((item,index) => <div key={index} 
                              className={'index-data ' + item.tag + (item.tag === 'projectGames' && item.outstyle === '4' ? ' projectGamesTravelDis ' : '')} 
                              style={{backgroundImage:'',
                                      backgroundSize: "cover",
                                      backgroundPosition: "right",
                                      backgroundColor:'white'}}>
            {item.tag !== 'specialBanner' && item.tag !== 'videoList' && item.tag !== 'agame' && item.outstyle != 3
            && item.outstyle != 4 ? <div className={'data-head ' + item.tag}>
                <div className="text"><img src={item.title_image} /></div>
                {item.tag === 'projectGames' ? <Link
                  to={(() => {
                    if(item.style == 6) {
                      return '/party/beta?id=' + item.id
                    } else {
                      return '/game/project?id=' + item.id;
                    }
                  })()} 
                  className="more">更多<img src="/resources/welfare/left-back.png" className="left-back"/></Link> : null}
                {item.tag === 'startingGames' ? <Link to="/game/today-start" className="more">更多<img src="/resources/welfare/left-back.png" className="left-back"/></Link> : null}
                {item.tag === 'reserverGames' ? <Link to="/game/reserve" className="more">更多<img src="/resources/welfare/left-back.png" className="left-back"/></Link> : null}
                {item.tag === 'diycategory' ? <Link to={'/game/project?id=' + item.id} className="more">更多<img src="/resources/welfare/left-back.png" className="left-back"/></Link> : null}
                {/* {item.tag === 'diycategory' ? <div onClick={() => {
                  redux.dispatch({
                    type: 'type',
                    data: {
                      screenRouter: {
                        screen: '/game',
                        type: 0,
                        tag: item.target_game_cate,
                      },
                    }
                  });
                }} className="more">更多></div> : null} */}
              </div> : null}
            {item.outstyle != 3 && item.outstyle != 4 && item.tag !== 'reserverGames' && item.tag !== 'agame' ?
            <div className="tips">
              {item.tips}
            </div> : null}
            <div className={'data-body ' + item.tag}>
              {
                (()=>{
                  if (item.tag === 'todayGames') {
                    return <div className="new-today">
                      <div className='gametitle'>{item.title}</div>
                      {(() => {
                        return item.list.map((game, index) => game ? <Link key={index} to={'/game?id=' + game.game_id} className="new-today-item">
                        <div className="icon">
                          <Image src={game.game_image?.thumb}/>
                          {
                            game?.lablePosition == 2 && game.top_lable != '' ?
                            <div className="iconlable jx" style={{backgroundImage: 'linear-gradient('+game?.lableColor.t+', '+game?.lableColor.b+')'}}>
                              {game.top_lable}
                            </div>
                            : null
                          }
                        </div>
                        <div className="subject">
                          <div className="text">
                            <div className="name" >
                              <div className={"named" + (game.nameRemark ? " name-new" : "") }>{game?.lablePosition == 1 && game.top_lable != '' ? <span>{game.top_lable }</span> : null }{game.game_name}</div>
                              <div className="time">
                                <span>{game.start_time.split(':')[0]}</span>
                                <i>:</i>
                                <span>{game.start_time.split(':')[1]}</span>
                                首发
                              </div>
                            </div>

                            <div className="info1" style={{marginTop:Object.keys(game.bottom_lable).length == 0 && !game.is_support_accele && !game.is_easy_play ? '8px' : '0'}}>
                              <img src="/resources/game/stat.png" />
                              <span>{game.score}</span>
                              {game.game_classify_type}
                              <Subtitle>{game.nameRemark}</Subtitle>
                            </div>
                            <div className="info3">
                              {
                                game.bottom_lable ? <>
                                {Object.keys(game.bottom_lable).length > 0 ? 
                                <div >
                                  <span className="second-text" style={{color:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.right_content}</span>
                                </div> : null } </> : null
                              }
                              {game.exclusive_game_describe?.length > 0 ? <div className="speed">{game.exclusive_game_describe}</div> : null}
                              {game.is_support_accele ? <div className="speed">可加速</div> : null}
                              {game.is_easy_play ? <div className="speed">省心玩</div> : null}
                            </div>

                            {/* <div className="info1">

                              {game.game_classify_type}
    
                              {game.game_species_type == 3 ? " " : <>&nbsp;|&nbsp;{game.howManyPlay}人在玩</>}
              
                              
                              <div className="time">
                                <span>{game.start_time.split(':')[0]}</span>
                                <i>:</i>
                                <span>{game.start_time.split(':')[1]}</span>
                              </div>
                              
                            </div> */}
                            {/* <div className="info2">
                              {game.game_desc ? <>{game.game_desc?.split('+').map(item => <div className="tag">{item}</div>)}</> : <><span style={{color: infoTextColor}}>{game.introduction}</span></>}
                            </div> */}

                            {/* {
                              game.bottom_lable ? 
                              <>
                              {
                                Object.keys(game.bottom_lable).length > 0 ?
                                <div className="info3">
                                    <span className="first-text" style={{background:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.left_content}</span><span className="second-text" style={{color:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC',borderColor:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.right_content}</span>
                                </div>
                                :null
                              }
                              </>
                            :null} */}
                            {game.discount < 1 ? <div className="discount" style={{backgroundImage:'url(/resources/game/discount-text-bg.png)'}}><span>{game.discount * 10}</span>折</div> : null}
                          </div>
                          {/* <div className="btn">{game.game_species_type == 3 ? "开始" : "下载"}</div> */}
                        </div>
                      </Link> :null);
                      })()}
                    </div>
                  } else if (item.tag === 'recommendGames') {
                    return <div className="items">
                      {item.recommendGameslist.map(game => <Link to={'/game?id=' + game.game_id} className="item" onClick={()=>clickTheGameInTheTopic(game.game_name, item?.title ? item.title : '专题')}>
                        <div className="icon"><Image src={game.game_image.thumb}/></div>
                        <div className="name">{game.game_name}</div>
                        <div className="info">{game.game_classify_type}</div>
                      </Link>)}
                    </div>
                  } else if (item.tag === 'projectGames'){
                    if(item.outstyle == '2') {
                    return <div className="items">
                      {[item.projectGameslist[0], item.projectGameslist[1], item.projectGameslist[2], item.projectGameslist[3]].map(game => 
                        <>
                        {game ?
                          <Link className="item" to={(()=>{
                            //跳转类型
                            if(item.jump_type == 'tradeGoods') {
                              return '/trade/good?source=czjl';
                            } else {
                              return '/game?id=' + game.game_id;
                            }
                          })} 
                          onClick={()=>clickTheGameInTheTopic(game.game_name, item?.title ? item.title : '专题')}
                          >
                          {/*  to={'/game?id=' + game.game_id}> */}
                            <div className="icon"><Image src={game.game_image.thumb} /></div>
                            <div className="name">{game.game_name}</div>
                            <div className="info">{game.game_classify_type}</div>
                            {/* {
                              game.nameRemark ?
                              <div className="nameRemark">
                                <span>
                                {game.nameRemark}
                                </span>
                                
                              </div>
                              : null
                            } */}
                      
                          </Link>
                        : null}
                        </>
                      )}
                    </div>
                    } else if(item.outstyle == '3') {
                      return <div className="" style={{paddingTop: '15px'}}>
                        {/* <div className='gametitle'>{item.title}</div> */}
                        {item.projectGameslist?.map(game => <div className={'project-item-third'}><Link 
                        to={(()=>{
                          //跳转类型
                          if(item.jump_type == 'tradeGoods') {
                            return '/trade/good?source=czjl';
                          } else {
                            return '/game?id=' + game.game_id;
                          }
                        })}
                        onClick={()=>clickTheGameInTheTopic(game.game_name, item?.title ? item.title : '专题')}
                        >
                          <div className="item-base">
                            <div className="icon"><Image src={game.game_image.thumb}/></div>
                            <div className="text">
                              <div className="name">
                                <div className={"named" + (game.nameRemark ? " name-new" : "") }>{game?.lablePosition == 1 && game.top_lable != '' ? <span>{game.top_lable }</span> : null }{game.game_name}</div>
                                  {game.nameRemark != '' ? <div className="nameRemark-new">{game.nameRemark}</div> : null}
                                  {game.discount < 1 ? <div className="discount" style={{backgroundImage:'url(/resources/game/discount-text-bg.png)'}}><span>{game.discount * 10}</span>折</div> : null}
                                </div>


                                <div className="info1" style={{marginTop:(Object.keys(game.bottom_lable).length > 0 ? '0' : '8px')}}>
                                  <img src="/resources/game/stat.png" />
                                  <span>{game.score}</span>
                                  {game.game_classify_type}
                                  {game.game_species_type != 3 ? <>&nbsp;·&nbsp;{game.howManyPlay}人在玩</> : null}
                                </div>
                                {
                                  game.bottom_lable ? 
                                  <>
                                  {Object.keys(game.bottom_lable).length > 0 ? 
                                  <div className="info3">
                                    <span className="second-text" 
                                          style={{color:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.right_content}</span>
                                  </div> : null }
                                  </>
                                  : null
                                }
                              {/* <div className="info1">
                                {game.game_classify_type}
                                {game.game_species_type != 3 ? <>&nbsp;|&nbsp;{game.howManyPlay}人在玩</> : null}
                              </div>
                              <div className="info2">
                                {game.game_desc ? game.game_desc.split('+').map(item => <div
                                  className="tag">{item}</div>) : <span style={{color:infoTextColor}}>{game.introduction}</span>}
                              </div> */}
                            </div>
                          </div>
                          <div className="item-img"><Image src={item.top_image} alt="" className="project-img-four"/></div>
                          <div className="item-desc">{item.tips}</div>
                        </Link>
                        </div>)}
                      </div>
                    } else if(item.outstyle == '4') {
                      return <div className="" style={{position:'relative'}}>
                        <span className='gametitle' style={{backgroundImage: 'url(resources/index/zbtj.png)'}}>{item.title}</span>
                        <NavLink style={{
                          position: 'absolute',
                          top:'10px',
                          right:'0'
                        }} user={true} onClick={()=>{
                          if (user.isCheckAuth && !user.isRealNameAuth){
                            setIsCheckAuth(true)
                            return
                          }
                          general.axios.post('/base/yunxin/joinTeams',{topic_id:item.projectGameslist[0].maiyou_gameid})
                          .then(res=>{
                            const { data, status} = res.data
                            if (status.succeed){
                              const {tid} = data.info
                              if (localStorage.getItem('yx_login')){
                                history.push(`/chat?tid=${tid}`)
                              }else{
                                PubSub.publish('loginSubscribe',true)
                                setTimeout(()=>{
                                  history.push(`/chat?tid=${tid}`)
                                },3000)
                              }
                            }else{
                              Dialog.error(status.error_desc)
                            }
                          })
                        }}>
                          <span style={{
                            color:'#999',paddingRight:'2px'
                          }}>加入群聊</span>
                          <RightOutlined style={{color:'#999',fontSize:'12px'}}></RightOutlined>
                        </NavLink>
                        {item.projectGameslist?.map((game,index) => <div key={index} className={'project-item-third'}><Link 
                          to={(()=>{
                            //跳转类型
                            if(item.jump_type == 'tradeGoods') {
                              return '/trade/good?source=czjl';
                            } else {
                              return '/game?id=' + game.game_id;
                            }
                          })}
                          onClick={()=>clickTheGameInTheTopic(game.game_name, item?.title ? item.title : '专题')}
                          >
                            <div className="item-base">
                              <div className="icon"><Image src={game.game_image.thumb}/></div>
                              <div className='subject'>
                                <div className="text">
                                  <div className="name">
                                    <div className={"named" + (game.nameRemark ? " name-new" : "") }>{game?.lablePosition == 1 && game.top_lable != '' ? <span>{game.top_lable }</span> : null }{game.game_name}</div>
                                    {game.discount < 1 ? <div className="discount" style={{backgroundImage:'url(/resources/game/discount-text-bg.png)'}}><span>{game.discount * 10}</span>折</div> : null}
                                  </div>

                                  <div className="info1" style={{marginTop:Object.keys(game.bottom_lable).length == 0 && !game.is_support_accele && !game.is_easy_play ? '8px' : '0'}}>
                                    <img src="/resources/game/stat.png" />
                                    <span>{game.score}</span>
                                    {game.game_classify_type}
                                    <Subtitle>{game.nameRemark}</Subtitle>
                                  </div>
                                  <div className="info3">
                                    {
                                      game.bottom_lable ? <>
                                      {Object.keys(game.bottom_lable).length > 0 ? 
                                      <div >
                                        <span className="second-text" style={{color:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.right_content}</span>
                                      </div> : null } </> : null
                                    }
                                    {game.exclusive_game_describe?.length > 0 ? <div className="speed">{game.exclusive_game_describe}</div> : null}
                                    {game.is_support_accele ? <div className="speed">可加速</div> : null}
                                    {game.is_easy_play ? <div className="speed">省心玩</div> : null}
                                  </div>
                                </div>
                              </div>

                            </div>
                          </Link>
                        </div>)}
                      </div>
                    } else if(item.outstyle == '6') {
                      return <Link 
                      to={"/party/beta?id=" + item.id} onClick={()=>clickTheTopicToSeeMore(item?.title ? item.title : '专题')}>
                        <Image src={item.top_image} alt="" className="project-img-four"/>
                      </Link>
                    } else {
                    return <Link 
                    to={(()=>{
                      //跳转类型
                      if(item.jump_type == 'tradeGoods') {
                        return '/trade/good?source=czjl';
                      } else {
                        return '/game/project?id=' + item.id;
                      }
                    })}
                    onClick={()=>clickTheTopicToSeeMore(item?.title ? item.title : '专题')}
                    >
                      <Image src={item.top_image} alt="" className="project-img-four"/>
                    </Link>
                    }
                  } else if (item.tag === 'hotGames') {
                    return <div className="">
                      {(() => {
                        let start = 1;
                        let end = Math.ceil(item.hotGameslist.length / 3) + 1;
                        let ts = Array(end - start).fill(0).map((el, i) => start + i);
                        return item.hotGameslist.map((game, index) => game?<Link to={'/game?id=' + game.game_id} className="item" onClick={()=>clickTheGameInTheTopic(game.game_name, item?.title ? item.title : '专题')}>
                            <div className="entity">
                              <div className="icon">
                                <Image src={game.game_image.thumb}/>
                                {
                                  game?.lablePosition == 2 && game.top_lable != '' ?
                                  <div className="iconlable jx" style={{backgroundImage: 'linear-gradient('+game?.lableColor.t+', '+game?.lableColor.b+')'}}>
                                    {game.top_lable}
                                  </div>
                                  : null
                                }
                              </div>
                              <div className="text">
                                <div className="name" style={{width: game.discount < 1 ? '82%' : '100%' }}>
                                  {/* {game.top_lable != '' ? <div className="span">{game.top_lable }</div> : null } */}
                                  <div className={" " + (game.nameRemark ? " name-new" : "")}>
                                    {game?.lablePosition == 1 && game.top_lable != '' ? <div className="span">{game.top_lable}</div> : null }
                                    {game.game_name}
                                  </div>
                                  {game.nameRemark ? <div className="nameRemark-new">{game.nameRemark}</div> : null}
                                </div>

                                <div className="info1" style={{marginTop:(Object.keys(game.bottom_lable).length > 0 ? '0' : '8px')}}>
                                  <img src="/resources/game/stat.png" />
                                  <span>{game.score}</span>
                                  {game.game_classify_type}
                                  {game.game_species_type != 3 ? <>&nbsp;·&nbsp;{game.howManyPlay}人在玩</> : null}
                                </div>
                                {
                                  game.bottom_lable ? 
                                  <>
                                  {Object.keys(game.bottom_lable).length > 0 ? 
                                  <div className="info3">
                                    {/* <span className="first-text" 
                                          style={{background:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.left_content}</span> */}
                                    <span className="second-text" 
                                          style={{color:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.right_content}</span>
                                  </div> : null }
                                  </>
                                  : null
                                }

                                {/* <div className="info1">
                                  {game.game_classify_type}
                                  {game.game_species_type != 3 ? <>&nbsp;|&nbsp;{game.howManyPlay}人在玩</> : null}
                                </div>
                                <div className="info2">
                                  {game.game_desc ? game.game_desc.split('+').map(item => <div className="tag">{item}</div>) : <span style={{color:infoTextColor}}>{game.introduction}</span>}
                                </div>
                                {
                                  game.bottom_lable ? 
                                  <>
                                  {Object.keys(game.bottom_lable).length > 0 ? 
                                  <div className="info3">
                                    <span className="first-text" style={{background:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.left_content}</span><span className="second-text" style={{color:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC',borderColor:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.right_content}</span>
                                  </div> : null }
                                  </>
                                  : null
                                } */}
        
                                {game.discount < 1 ? <div className="discount" style={{backgroundImage:'url(/resources/game/discount-text-bg.png)'}}><span>{game.discount * 10}</span>折</div> : null}
                              </div>
                            </div>
        
                            {(() => {
                              if(item.theme_style == 'all_image') {
                                if(game?.banner_url) {
                                  return <div className="entity-img">
                                    <img src={game?.banner_url} />
                                  </div>
                                }
                              } else if (item.theme_style == 'first_image') {
                                if(index == 0) {
                                  return <div className="entity-img">
                                    <img src={game?.banner_url} />
                                  </div>
                                }
                              }
                            })()}
                          </Link>:null);
                      })()}
                    </div>
                  } else if (item.tag === 'specialBanner') {
                    return <div className="items clearfix">
                      {item.specialBannerlist.map(item => <NavLink
                        className="item"
                        to={(() => {
                          if (item.type === 'h5') {
                            return item.param;
                          }
                        })()}
                        iframe={item.type === 'h5' ? {} : false}
                        user={item.type === 'h5' && item.is_verification_login}
                      >
                        <Image src={item.banner_img} alt=""/>
                      </NavLink>)}
                    </div>
                  } else if (item.tag === 'reserverGames') {
                    return <Swiper onSwiper={swiper => swipers.push(swiper)}>
                      {item.reserverGameslist.map(game => <SwiperSlide>
                        <Link to={'/game?id=' + game.game_id} className="item" onClick={()=>clickTheGameInTheTopic(game.game_name, item?.title ? item.title : '专题')}>
                          <div className="img" style={{backgroundImage: 'url(' + game.video_img_url + ')'}}/>
                          <div className="container">
                            <div className="icon"><Image src={game.game_image.thumb}/></div>
                            <div className="text">
                              <div className="name">
                                {/* {game.game_name} */}
                                <div className={" " + (game.nameRemark ? " name-new" : "")}>
                                  {game.game_name}
                                </div>
                                {game.nameRemark ? <div className="nameRemark-new" style={{clear: 'both'}}>{game.nameRemark}</div> : <div ></div>}
                              </div>
                              <div className="info1">
                                {/* {
                                  game.nameRemark ?
                                  <span className="nameRemark">
                                    {game?.nameRemark}&nbsp;
                                  </span>
                                  : null
                                } */}
                                {/* {game.game_classify_type} */}
                                {/* {
                                  game.nameRemark ?
                                  <>{game.game_classify_type.replace(/(^\s*)|(\s*$)/g, "").replace(/\s+/g, "&")}</>
                                  :
                                  ""
                                } */}
                              </div>
                              <div className="time">
                                {moment(game.starting_time * 1000).format('MM月DD日 HH:mm')}首发
                              </div>
                            </div>
                            <div className="btn">查看</div>
                          </div>
                        </Link>
                      </SwiperSlide>)}
                    </Swiper>
                  } else if (item.tag === 'agame') {
                    return <Link className="item" to={"/game?id="+item?.agamelist[0]?.game_id}>
                      <div className="entity-img">
                        <img src={item?.agamelist[0]?.banner_url} />
                        <div className="img-tips" style={{backgroundImage: 'linear-gradient(#f0ebeb1f, '+item.bannerBgColor+' 80%, '+item.bannerBgColor+')'}}>
                          {/* 真开局送绝版龙魂七彩祥瑞！ */}
                          {item?.tips}
                        </div>
                      </div>
                      <div className="entity-dividing" style={{backgroundColor: ' ' + item.bannerBgColor +' '}}>
                        <div className="dividing-line">
                          <img src="/resources/index/dividing-line.png" />
                        </div>
                        <div className="entity">
                          <div className="icon"><Image src={item?.agamelist[0]?.game_image.thumb}/></div>
                          <div className="text">
                            <div className="name">
                              <div className={" " + (item?.agamelist[0]?.nameRemark ? " name-new" : "")}>
                                {item?.agamelist[0]?.game_name}
                              </div>
                              {item?.agamelist[0]?.nameRemark ? <div className="nameRemark-new" style={{clear: 'both'}}>{item?.agamelist[0]?.nameRemark}</div> : <div ></div>}
                            </div>

                            <div className="info1" style={{marginTop:'8px'}}>
                              <img src="/resources/game/stat.png" />
                              <span>{item?.agamelist[0]?.score}</span>
                              {item?.agamelist[0]?.game_classify_type}
                              {item?.agamelist[0]?.game_species_type != 3 ? <>&nbsp;·&nbsp;{item?.agamelist[0]?.howManyPlay}人在玩</> : null}
                            </div>
                            {/* <div className="info1">
                              {item?.agamelist[0]?.game_classify_type}
                              {item?.agamelist[0]?.game_species_type != 3 ? <>&nbsp;|&nbsp;{item?.agamelist[0]?.howManyPlay}人在玩</> : null}
                            </div>
                            <div className="info2">
                              {item?.agamelist[0]?.game_desc ? item.agamelist[0].game_desc.split('+').map(item => <div className="tag">{item}</div>) : <span style={{color:infoTextColor}}>{item?.agamelist[0]?.introduction}</span>}
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </Link>
                  } else if (item.tag === 'diycategory') {
                    item.hide = item.diycategorylist.length === 0;
                    return <div className="items ">
                      {item.diycategorylist.map((game, index) => index < 6 ?
                        <Link to={'/game?id=' + game.game_id} className={"item" + (index == 3 ? ' clear' : '') } onClick={()=>clickTheGameInTheTopic(game.game_name, item?.title ? item.title : '专题')}>
                          <div className="icon">
                            <Image src={game.game_image.thumb}/>
                            <div className="badge">
                              {/* {(()=>{
                                if(game.game_desc) {
                                  return game.game_desc.split('+')[1]
                                } else {
                                  if(game.discount < 1) {
                                    return <div><span>{game.discount * 10}</span>折</div>
                                  }
                                }
                              })()} */}
                              {game.game_desc.split('+')[1]}
                            </div>
        
                            <div className="type_label">
                              {game.discount < 1 ? <div><span>{game.discount * 10}</span>折</div> : ''}
                            </div>
                            {/* <div className="discount" style={{backgroundImage: 'url(/resources/game/discount-text-bg.png)'}}><span>{game.discount * 10}</span>折</div> */}
                          </div>
                          <div className="name">{game.game_name}</div>
                          {/* {
                            game.nameRemark ?
                            <div className="nameRemark">
                              <span>
                              {game.nameRemark}
                              </span>
                            </div>
                            : null
                          } */}
                        </Link> : null)}
                    </div>
                  } else if (item.tag === 'videoList') {
                    return <div className="videoList">
        
                    </div>
                  } else if (item.tag === 'hotCategory') {
                    return <div className="items clearfix">
                      {item.hotCategorylist.map(item => <div className="item">
                        <div className="container" onClick={() => {
                          redux.dispatch({
                            type: 'type',
                            data: {
                              screenRouter: {
                                screen: '/game',
                                type: 0,
                                tag: item.game_classify_id,
                              },
                            }
                          });
                        }}>{item.game_classify_name}</div>
                      </div>)}
                    </div>
                  }
                })()
              }
            </div>
          </div>)}
        </div>

        <div className="list">
          {gameGroups?.map((item,index)=><div key={index} className={'index-data ' + 'todayGames'}>
            <div className={'data-body ' + 'todayGames'}>
            {
              (()=>{
                return <div className="new-today">
                    <div className='gametitle'>{(() => {
                      if (item.text == moment().format('YYYY-MM-DD')) {
                        return '今日首发';
                      } else if (item.text == moment().subtract(1, 'd').format('YYYY-MM-DD')) {
                        return '昨日首发';
                      } else {
                        return item.text;
                      }
                    })()}</div>
                    {(() => {
                      return item.games.map((game, index) => game ? <Link key={index} to={'/game?id=' + game.game_id} className="new-today-item">
                      <div className="icon">
                        <Image src={game.game_image?.thumb}/>
                        {
                          game?.lablePosition == 2 && game.top_lable != '' ?
                          <div className="iconlable jx" style={{backgroundImage: 'linear-gradient('+game?.lableColor.t+', '+game?.lableColor.b+')'}}>
                            {game.top_lable}
                          </div>
                          : null
                        }
                      </div>
                      <div className="subject">
                        <div className="text">
                          <div className="name" >
                            <div className={"named" + (game.nameRemark ? " name-new" : "") }>{game?.lablePosition == 1 && game.top_lable != '' ? <span>{game.top_lable }</span> : null }{game.game_name}</div>
                            
                          </div>

                          <div className="info1" style={{marginTop:Object.keys(game.bottom_lable).length == 0 && !game.is_support_accele && !game.is_easy_play ? '5px' : '0'}}>
                            <img src="/resources/game/stat.png" />
                            <span>{game.score}</span>
                            {game.game_classify_type}
                            <Subtitle>{game.nameRemark}</Subtitle>
                          </div>
                          <div className="info3">
                            {
                              game.bottom_lable ? <>
                              {Object.keys(game.bottom_lable).length > 0 ? 
                              <div >
                                <span className="second-text" style={{color:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.right_content}</span>
                              </div> : null } </> : null
                            }
                            {game.exclusive_game_describe?.length > 0 ? <div className="speed">{game.exclusive_game_describe}</div> : null}
                            {game.is_support_accele ? <div className="speed">可加速</div> : null}
                            {game.is_easy_play ? <div className="speed">省心玩</div> : null}
                          </div>
                          {/* <div className="info1">
                            
                            {game.game_classify_type}
                            
                            {game.game_species_type == 3 ? " " : <>&nbsp;|&nbsp;{game.howManyPlay}人在玩</>}
                            
                          </div>
                          <div className="info2">
                            {game.game_desc ? <>{game.game_desc?.split('+').map(item => <div className="tag">{item}</div>)}</> : <><span style={{color: infoTextColor}}>{game.introduction}</span></>}
                          </div>
                          {
                            game.bottom_lable ? 
                            <>
                            {
                              Object.keys(game.bottom_lable).length > 0 ?
                              <div className="info3">
                                  <span className="first-text" style={{background:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.left_content}</span><span className="second-text" style={{color:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC',borderColor:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.right_content}</span>
                              </div>
                              :null
                            }
                            </>
                          :null} */}
                          {game.discount < 1 ? <div className="discount" style={{backgroundImage:'url(/resources/game/discount-text-bg.png)'}}><span>{game.discount * 10}</span>折</div> : null}
                        </div>
                      </div>
                    </Link> :null);
                    })()}
                  </div>
              })()
            }
            </div>
          </div>)}
        </div>

        {isCheckAuth?
          <div className="pay-check-auth">
            <div className="auth-index">
              <div className="close" onClick={() => setIsCheckAuth(false)}>
                <img src="/resources/user/close.png" />
              </div>
              <div className="title">
                <div className="title-img">
                  <img src="/resources/user/auth-title.png" />
                </div>
                <div>
                  <h3>实名认证</h3>
                  <p>
                  {config.authTopTips}
                  </p>
                </div>
              </div>

              <div className="auth-user-realname">
                <div className="auth-user-input">
                  <div className="name">真实姓名</div>
                  <input name="nick_name" type="text" placeholder="请输入姓名" autoComplete="off" value={authData.real_name} onChange={e => setAuthData({...authData, real_name: e.target.value})} />
                </div>
                <div className="auth-user-input">
                  <div className="name">身份证号</div>
                  <input name="number" type="text" placeholder="请输入身份证号" autoComplete="off" value={authData.identity_card} onChange={e => setAuthData({...authData, identity_card: e.target.value})} />
                </div>
                <span>
                {config.authBottomTips}
                </span>
                <div className="auth-user-btn" onClick={certifiy}>立即认证</div>
              </div>
            </div>
          </div> : null
          }

        {!more ? <div className="view-end">别撩啦！已经到底啦~</div> : null}

      </div>
    </div>
  )
}
